/* Reset all margins and paddings */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.home {
    position: relative;
}

/* Set body background color */
body {
    background-color: #fff;
}

/* Style for the slider container */
.slider-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url(../../assets/images/dnd/fr12.jpg);
    background-size: fill;
    background-position: center;
}

/* Style for the caption overlay */
.caption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.5);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: background-image 0.5s ease-in-out;
}

.caption::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    /* Set the color and opacity of the overlay */
}

.zindex {
    z-index: 5;
}

/* Style for the header */
.heads {
    text-align: center;
    margin-bottom: 1rem;
}

/* Style for the language links */
.home__menu {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 500;
    transform: translate(-50%, -50%);

}


.home__menu_language {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.5rem 8rem !important;

}
/* .home__menu_language {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 150px;
} */

.home__menu_language,
.home__menu_language-list_link {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    min-width: 150px;
    max-width: 150px;
    padding: 0.5rem 1rem;
    margin: 1rem;
    border: 2px solid #fff;
    border-radius: 2rem;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.5s ease-in-out;

}

.home__menu_language-list_link a,
.home__menu_language-link {
    color: #fff;
    text-align: center !important;
    width: 100% !important;
    height: 100% !important;

}

.home__menu_language-link a {
    padding-left: 1rem;
}

.home__menu_language-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 400px;
    margin: auto;
    cursor: pointer;

}


.home__menu_language:hover{
    background-color: #fff;
}
 .home__menu_language:hover .home__menu_language-link ,   .home__menu_language-list_link:hover a
 
 {
    color: var(--bs-link-color) !important; }
.home__menu_language-list_link:hover
 {
    background-color: #fff;
     color: #000; 
}

/* Media queries for different screen sizes */
@media (max-width: 767px) {

    /* Style for mobile screens (galaxy, iphone) */
    .caption {
        font-size: 1.5rem;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    /* Style for tablet screens */
    .caption {
        font-size: 1.75rem;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {

    /* Style for laptop screens */
    .caption {
        font-size: 2rem;
    }
}

@media (min-width: 1200px) {

    /* Style for large screens */
    .caption {
        font-size: 2.5rem;
    }
}