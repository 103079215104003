.dipndip__page.en {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;

}

.dipndip__category {
    width: 330px;
    margin-top: .7rem;
}

.dipndip__category_btn-category {
    color: #d74a49;
    padding: .75rem;
    background-color: #16161b;
    margin: .5rem 0;
    text-transform: uppercase;
    font-size: 1.2rem;
    cursor: pointer;

}

.dipndip__category_btn-category:hover {
    background-color: #22222f;
    color: #f60505;
}

.dipndip__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}



.dipndip__container_item-details {
    display: flex;
    align-items: center;
    flex-direction: column;

}



.dipndip__container_item-details_info {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: .5rem;
    border: 1px solid blanchedalmond;

}

.dipndip__container_item-details_info-img {
    max-width: 375px;
    max-height: 400px;
    min-width: 375px;
    min-height: 400px;
    object-fit: contain;

}

.dipndip__container_item-details_info-name {
    color: #d74a49;
    padding: 1rem 0;
    font-size: 1.8rem;
    margin-bottom: .5rem;
}

.dipndip__container_item-details_info-price {
    color: #f8cc1b;
    font-size: 1.5rem;
}

@media screen and (max-width:413px) {
    .dipndip__page.en {
        justify-content: center;
    }

    .dipndip__category {
        flex-direction: column;

        margin: 1rem 0;
        width: 100%;
    }

    .dipndip__category_btn-category {
        text-align: center;
    }
}

@media (min-width:414px) and (max-width:767px) {

    .dipndip__page.en {
        justify-content: center;
        flex-direction: column;
    }

    .dipndip__category {
        flex-direction: column;
        margin: 1rem 0;
        width: 100%;
    }

    .dipndip__category_btn-category {
        text-align: center;
    }

    .dipndip__container {
        justify-content: center;
    }

    .dipndip__container_item-details_info {
        margin: 0.5rem;
    }

}

@media (min-width:768px) and (max-width:991px) {
    .dipndip__page.en {
        flex-direction: row;
        justify-content: flex-start;
    }

    .dipndip__category {
        margin-right: 1rem;
        width: 330px;
    }

    .dipndip__container {
        margin-top: .6rem;
    }

    .dipndip__container_item-details_info {
        margin: 0.5rem;
    }

}

@media (min-width:991px) and (max-width: 1024px) {
    .dipndip__page.en {
        flex-direction: row;
        justify-content: flex-start;
    }

    .dipndip__category {
        margin-right: 1rem;
        min-width: 330px;
    }

    .dipndip__container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .dipndip__container_item-details_info {
        margin: 0.5rem;
    }

}

@media screen and (min-width: 1024px) {

    .dipndip__page.en {
        flex-direction: row;
        justify-content: flex-start;
    }

    .dipndip__category {
        margin-right: 1rem;
        min-width: 330px;
    }

    .dipndip__container {

        display: flex;
        justify-content: stretch;
        align-items: normal;
        flex-direction: row;
        margin: 0.5rem 0;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .dipndip__container_item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .dipndip__container_item-details_info {
        flex: 0 1 1;
        margin: 1rem .75rem;
    }

}