.main {

    right: 0;
    display: flex;
    padding: 1rem;
    margin-bottom: 3rem;
}

.main .arabic {
    display: flex;
    align-items: center;
    direction: rtl;
    flex: auto !important;


}

.main .english {
    display: flex;
    align-items: center;
    direction: ltr;
    flex: auto !important;
}

.logo {
    display: flex;
    align-items: center;
}

.main .logoimage {
    width: 150px;
    background-color: wheat;
    padding: 0.2rem 1rem;
    border-radius: 24px;

}

.main .langs {
    display: none;
}

.btn-icon {
    background-color: transparent;
    top: 30px;
    position: absolute;
}

.btn-icon.ar {
    left: 1rem;
}

.btn-icon.en {
    right: 1rem;
}

.main .btn-icon:focus {
    border: none;
    outline: none;

}

.main .btn-icon svg {
    color: white;
    font-size: 2.5rem;
    padding: .3rem;
}

.navbar-items-column:not(.show) {
    display: none;
}

.main .arabic .navbar-items {
    margin-right: 2rem;
}

.navbar-items .ulist {
    margin-bottom: 0;
}

.navbar-items .ulist {
    justify-content: space-around;
    flex-direction: row;
    display: flex;
    align-items: center;
}

.navbar-items .ulist li {
    margin: 1.2rem .5rem;
    align-items: center;
}

.ulist li a {
    font-size: 1.3rem;
    color: #d74a49;
}


@media only screen and (max-width: 767px) {
    .navbar-column {
        width: 100%;
    }

    .navbar-items {
        display: none;
    }

    .navbar-items-column {
        /* margin-top: 4rem; */
    }

    .navbar-items-column.show .ulist {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 4.5rem;
        text-align: center;
    }
    
    
    
    .navbar-items-column .ulist li {
        background-color: #16161b;
        padding: .5rem;
        margin: .1rem;
    }

    .navbar-items-column .ulist li a {
        margin: .5rem;
    }


    .navbar-items-column .ulist li:hover {
        background-color: #22222f;
        color: #f60505;
    }

    /*color: #d74a49;
    background-color: #16161b;*/

    .logo {
        position: absolute;
        top: 1.8rem;
    }

    .main .langs {
        margin: 0 1rem;
        display: block;
    }

    .main .langs a {
        margin: 0 1rem;
        color: #d74a49;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .main .langs.arb {
        left: 45%;
        top: 5%;
        position: absolute;
    }

    .main .langs.eng {
        right: 45%;
        top: 5%;
        position: absolute;

    }



}

/* 
@media screen and (min-width:678) {
    .navbar-collapse {
        display: flex;
    }
}

.navbar-items.show {
    display: flex;
    flex-direction: column;

}

.ulist {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 1rem;
}

.ulist li {
    padding: .5rem;
}

.ulist li a {
    font-size: 1.3rem;
    font-weight: bold;
    color: rgba(13, 55, 70, 0.815);
}@media screen and (max-width: 767px) {
    .main .icon {
        display: none;
    }

    .main .btn-icon-en,
    .main .btn-icon-ar {
        display: block;
    }
}

@media screen and (min-width: 768px) {

    .main .btn-icon-en,
    .main .btn-icon-ar {
        display: none;
    }

    .main navbar-items  {
        display: block;
    }
} */