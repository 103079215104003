  @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&family=Ubuntu:wght@300;400;700&display=swap');

  :root {
    --font-family: 'manrope', sans-serif;
  }

  .categoryLink {
    color: #d74a49;
    transition: .3s;
  }

  .categoryLink:hover {
    background-color: #22222f;
    color: #f60505;
    border-bottom: 1px solid #d74a49;
  }