* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

body {
    /* background-color: #414345 !important; */
        /* background-color: #232526 !important; */
        /* background: linear-gradient(to right, #000000, #232526); */
        background: linear-gradient(to right, #0e1c27, #000000,#082d4e);
    font-family: "Cairo", sans-serif !important
}

ul {
    margin: 0;
    padding: 0;
    list-style: none !important;
}


a {
    /* color: unset !important; */
    text-decoration: none !important;
}

.gradient__bg {
    /* global 92%+ browsers support */
    background: radial-gradient(circle at 3% 25%, rgba(4, 12, 24, 1) 0%, rgba(0, 0, 0, .4) 20%);

    background: -moz-radial-gradient(circle at 3% 25%, rgba(4, 12, 24, 1) 0%, rgba(238, 130, 238, 1) 100%);
    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 3% 25%, rgba(4, 12, 24, 1) 0%, rgba(238, 130, 238, 1) 100%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 3% 25%, rgba(4, 12, 24, 1) 0%, rgba(238, 130, 238, 1) 100%);

    /* ie 10+ 
        background: -ms-radial-gradient(circle at 3% 25%, rgba(4, 12, 24, 1) 0%, rgba(238, 130, 238, 1) 100%);*/

}



.gradient__text {
    /* background-color: var(--color-bg); */
    background: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
    ;
    background-clip: text;

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    margin: 4rem 6rem;
}

.scale-up-center {
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-2-11 12:23:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

/* @media screen and (max-width:700px) {
    .section__margin {
        margin: 4rem
    }

    .section__padding {
        padding: 4rem;
    }
}

/* tablests and big phones */
@media screen and (max-width:700px) {
    .section__margin {
        margin: 4rem
    }

    .section__padding {
        padding: 4rem;
    }
}

/* Smart phones */
@media screen and (max-width:550) {
    .section__margin {
        margin: 4rem 2rem
    }

    .section__padding {
        padding: 4rem 2rem;
    }
}

.scale-up-center {
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}